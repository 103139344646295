<template>
	<div class="overall">
		<!-- 上半部分 -->
		<el-card class="chart-card">
			<!-- 上半部分右边内容 -->
			<div class="plane">
				<!-- 右边头部 -->
				<div class="ritherd">
					<el-col :span="8">
						<div class="impose">
							<el-card class="a" shadow="hover">
								<div class="amount">
									<div class="union">
										<el-icon size="35px" color="#ffffff">
											<WalletFilled />
										</el-icon>
									</div>
									<div class="magnitude">
										<div class="count">{{departmentCount}}</div>
										<div class="crew">总分配积分</div>
									</div>
								</div>
							</el-card>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="impose1">
							<el-card shadow="hover">
								<div class="amount">
									<div class="union1">
										<el-icon size="35px" color="#ffffff">
											<Van />
										</el-icon>
									</div>
									<div class="magnitude">
										<div class="count1">{{userCount}}</div>
										<div class="crew">在途积分</div>
									</div>
								</div>
							</el-card>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="impose1">
							<el-card shadow="hover">
								<div class="amount">
									<div class="union2">
										<el-icon size="35px" color="#ffffff">
											<ShoppingCartFull />
										</el-icon>
									</div>
									<div class="magnitude">
										<div class="count2">{{integralCount}}</div>
										<div class="crew">已换购积分</div>
									</div>
								</div>
							</el-card>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="impose1">
							<el-card shadow="hover">
								<div class="amount">
									<div class="union3">
										<img class="guoqi" src="../../../src/assets/guoqi.png" alt="">
									</div>
									<div class="magnitude">
										<div class="count3">{{kepaifa}}</div>
										<div class="crew">过期积分</div>
									</div>
								</div>
							</el-card>
						</div>
					</el-col>
				</div>

				<!-- 表格内容 -->
				<!-- 搜索框 -->
				<div>
					<div class="grabble">
						<div style="width: 70%;display: flex;">
							<!-- 姓名 -->
							<!-- <el-input
              class="hunt"
              v-model="designation"
              placeholder="请输入工会名称"
              size="large"
              @input="orangutan"
            /> -->
							<!-- 编码 -->
							<!-- <el-input
              class="hunt"
              v-model="serial"
              placeholder="请输入分配管理员"
              size="large"
              @input="howl"
            /> -->
							<!-- 会员组织名称搜索 -->
							<el-select @focus="closeIt" class="hunt" v-model="ability" placeholder="请选择单位名称"
								size="large" @click="multiple" clearable @change="numerical" ref="selectIt" />
							<!-- 派发时间 -->
							<el-date-picker v-model="value1" type="daterange" range-separator="至"
								start-placeholder="开始时间" end-placeholder="结束时间" size="large" @change="immediUpdate" />
							<!-- 搜索 -->
							<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
								:loading='formative'><el-icon class="cancelgu">
									<Search />
								</el-icon>搜索</el-button>
						</div>
						<div>
							<el-button type="primary" size="large" @click="allot" :loading='whirl'>
								<img src="../../assets/pei.png" style="width:17px;margin-right: 6px" alt="">
								积分分配</el-button>
							<el-button type="primary" size="large" @click="allott" :loading='whirl'>
								<img src="../../assets/daochu.png" style="width:15px;margin-right: 6px" alt="">
								导出</el-button>
						</div>
					</div>
					<div class="cooperate">
						<div class="statistics">
							统计：{{zong}}条
						</div>

					</div>
				</div>
				<!-- 表格 -->
				<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain"
					border :header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
					<!-- 工会编码 -->
					<el-table-column align="center" label="工会编码" prop="unionCode" :show-overflow-tooltip='true' />
					<!-- 工会名称 -->
					<el-table-column label="工会名称" prop="unionName" align="center" :show-overflow-tooltip='true'>
					</el-table-column>
					<!-- 工会标识 -->
					<el-table-column label="工会标识" prop="unionAbbreviation" align="center" :formatter="quantum"
						:show-overflow-tooltip='true'>
					</el-table-column>
					<!-- 积分值 -->
					<el-table-column label="积分值" prop="integralValue" align="center" :formatter="quantum"
						:show-overflow-tooltip='true'>
					</el-table-column>
					<!-- 分配管理员 -->
					<el-table-column label="分配管理员" prop="name" align="center" :formatter="quantum"
						:show-overflow-tooltip='true'>
					</el-table-column>
					<!-- 分配时间 -->
					<el-table-column label="分配时间" prop="createTime" align="center" :formatter="reach"
						:show-overflow-tooltip='true'>
					</el-table-column>
					<!-- 备注 -->
					<el-table-column label="备注" prop="reason" align="center" :formatter="quantum"
						:show-overflow-tooltip='true'>
					</el-table-column>
					<!-- <el-table-column label="操作" align="center" width="250" >
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.userId)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>历史查看</el-button
            >
          </template>
        </el-table-column> -->
				</el-table>
				<!-- 表格分页 -->
				<!-- 一页时候隐藏 hide-on-single-page="true" -->
				<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
					:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" />
			</div>
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />

				<!-- <div v-for="(item,index) in treeData" :key="index" @click="dendrogram(item)">
          {{item.unitName}}
        </div> -->
			</s3-layer>
		</div>
		<!-- 导出成功弹窗 -->
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		WalletFilled,
		Van,
		ShoppingCartFull,
		Search,
		Download
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount,
		onActivated
	} from 'vue';
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		PFintegral,
		ZClist,
		FYintegral,
		FPtotal
	} from "../../../utils/api";
	import {
		useStore
	} from "vuex";
	import qs from 'qs';
	import moments from 'moment'
	// import moments from 'moment'
	export default {
		name: "allocating",
		computed: {},
		setup() {
			const route = useRoute();
			// 搜索按钮加载
			let formative = ref(false)
			// 成功弹窗
			let anew = ref(false)

			let quoth = ref('')

			// 下拉隐藏
			let selectIt = ref()
			let closeIt = () => {
				selectIt.value.blur();
			}



			// 积分分配按钮
			const allot = () => {
				router.push({
					path: '/FPintegral'
				})
			}
			// 导出按钮
			const allott = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
					// 开始时间 结束时间
					startTime: a.value,
					endTime: b.value,
					// 工会标识
					unitAbbreviation: unitAbbreviation.value,
				}
				console.log(data)
				FPtotal(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}
			// 加载中
			const acting = ref(false)
			// 按钮禁用
			const jinyong = ref(false)
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					unitAbbreviation.value = ''
				}
			}

			// 导入
			// const error=(e)=>{
			//   console.log(e)
			// }
			const pleased = ref()




			// 数据总共条数
			let zong = ref(null)
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format().substring(0, 10)
				}
			}
			// 列表数据循环
			const filterTableData = ref([])
			// 表格数据接口
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value
				}
				FYintegral(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						zong.value = res.data.data.page.totalCount
						filterTableData.value = res.data.data.page.list
						// console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 分页获取积分的派发日志
			// 动态加载数据
			let branches = ref(6)
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				console.log(size)
				pagesize.value = size
				branches.value = size
				if (ability.value !== '' || a.value !== '' || b.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				texts.value = '加载中'
				if (ability.value !== '' || a.value !== '' || b.value !== '') {
					search()
				} else {
					list()
				}
				// console.log(currentPage.value)
				// console.log(branches.value )
			}


			// 加载中
			let texts = ref('加载中')
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				if (translate.value == 1) {
					a.value = ''
					b.value = ''
					unitAbbreviation.value = ''
					texts.value = '加载中'
					branches.value = 6
					currentPage.value = 1
					list()
					// 所有组织列表接口
					lang()
				}
			})

			let search1 = () => {
				formative.value = true
				search()
			}


			// 搜索框点击
			let search = () => {
				// 时间截止
				// 工会code
				// console.log(Hcode.value)
				// 会员编码框值
				// console.log(serial.value)
				// 姓名
				// console.log(designation.value)
				console.log(a.value)
				console.log(b.value)
				gration()

				// 搜索接口
				let data = {
					limit: branches.value,
					page: currentPage.value,
					// 开始时间 结束时间
					startTime: a.value,
					endTime: b.value,
					// 工会标识
					unitAbbreviation: unitAbbreviation.value,
				}
				FYintegral(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						zong.value = res.data.data.page.totalCount
						filterTableData.value = res.data.data.page.list
						// console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					} else if (res.data.code !== 0) {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			let a = ref('')
			let b = ref('')

			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
				}
				// console.log(e)
			}

			// 搜索组织列表接口
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 标识
			let unitAbbreviation = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unitName
				unitAbbreviation.value = node.unitAbbreviation
				visible.value = false
				console.log(node)
			}
			let treeData = ref([])
			const yes1 = () => {
				form.coding = options.value.unionName
				visible1.value = false;
			};


			onMounted(() => {
				// 获取当前页path
				lang()
				list()
			})
			const lang = () => {
				ZClist().then((res) => {
					texture.value = res.data.data.unitList
					//  console.log(res)
				})
				// console.log('44657687')
			}
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				label: 'unitName',
			}
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 搜索组织弹窗
			let visible = ref(false)



			// 会员编码框
			let serial = ref('')
			// 搜索框变化值
			let orangutan = () => {
				// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
				//   list()
				//   console.log('空了')
				// }
				console.log('变化了')
			}
			// 姓名输入框值
			let designation = ref('')


			// 总派发积分
			const departmentCount = ref(0)
			// 在途积分
			const userCount = ref(0)
			// 过期积分
			const kepaifa = ref(0)
			// 已换购积分
			const integralCount = ref(0)
			// 派发积分 在途积分 已换购积分 
			const gration = () => {
				let data = {
					startTime: a.value,
					endTime: b.value,
					unitAbbreviation: unitAbbreviation.value
				}
				console.log(data)
				PFintegral(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// 总派发积分 statisticalIntegral
						departmentCount.value = res.data.data.statisticalIntegral.statisticalIntegral
						// 在途积分 effectiveIntegral
						userCount.value = res.data.data.statisticalIntegral.effectiveIntegral
						// 已换购积分 consumeIntegral
						integralCount.value = res.data.data.statisticalIntegral.consumeIntegral
						// 过期积分 invalidIntegral
						kepaifa.value = res.data.data.statisticalIntegral.invalidIntegral
					} else if (res.data.code !== 0) {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
					// console.log(res)
				})
			}


			// 时间选择
			let value1 = ref('')
			const whirl = ref(false)
			onMounted(() => {
				//选择工会下拉框数据
				lang()
				//列表页数据
				list()
				//头部积分数据
				gration()
			})
			// 待办内容点击事件
			const router = useRouter()
			// 待办内容
			const tableData = [];
			// 头像加载失败
			const errorHandler = () => true;
			return {
				formative,
				search1,
				quoth,
				anew,
				selectIt,
				closeIt,
				// 分配积分按钮
				allot,
				pleased,
				acting,
				allott,
				// error,
				jinyong,
				numerical,

				immediUpdate,
				reach,
				filterTableData,
				// 可派发积分
				kepaifa,
				// 已换购积分
				integralCount,
				// 在途积分
				userCount,
				// 派发总积分
				departmentCount,
				// 分页
				zong,
				branches,
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 组织弹窗
				ability,
				texture,
				visible,
				// yes,
				dendrogram,
				defaultProps,
				multiple,
				yes1,
				treeData,
				// 会员编码框
				serial,
				// 搜索框
				search,
				// 姓名框变化值
				orangutan,
				// 会员姓名
				designation,
				// 时间选择
				whirl,
				value1,
				errorHandler,
				tableData,
				texts
			};
		},
		components: {
			Search,
			WalletFilled,
			Van,
			ShoppingCartFull,
		},
	};
</script>
<style lang='scss' scoped>
	.tishi {
		width: 15px;
		height: 15px;
		margin-right: 5px;
	}

	.trousers {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-top: 15px;
	}

	.minitype {
		font-size: 16px;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	.task {
		font-size: 14px;
	}

	.images {
		width: 20px;
		height: 20px;
	}

	.floating {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0%;
		top: 50%;
		width: 40px;
		height: 40px;
		background-color: #409EFF;
		border-radius: 5px 0 0 5px;
		z-index: 10;
	}

	.loading {
		width: 8px;
		height: 8px;
		border: 2px solid #C9E3FF;
		border-top-color: transparent;
		border-radius: 100%;
		animation: circle infinite 0.75s linear;
		margin-right: 5px;
	}

	// 转转转动画
	@keyframes circle {
		0% {
			transform: rotate(0);
		}

		100% {
			transform: rotate(360deg);
		}
	}





	.guoqi {
		width: 32px;
	}

	::v-deep .el-col-8 {
		max-width: 25.5% !important;
	}

	.limit {
		background-color: #F08080;
		color: #fff;
		--el-button-active-border-color: #F08080;
		--el-button-hover-border-color: #F08080;
	}

	.withhold {
		background-color: #BA55D3;
		color: #fff;
		--el-button-active-border-color: #BA55D3;
		--el-button-hover-border-color: #BA55D3;
	}

	.recall {
		background-color: #FFA07A;
		color: #fff;
		--el-button-active-border-color: #FFA07A;
		--el-button-hover-border-color: #FFA07A;
	}

	::v-deep .el-table__header {
		margin: 0 !important;
	}
	/* 调整表格边距 */
	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.upload-demo {
		margin-right: 10px;
	}

	.el-pagination {
		margin-top: 10px;
	}

	/* ::v-deep .chart-card::-webkit-scrollbar {
		display: block !important;
	} */

	// 派发积分模块
	.payout {
		font-size: 17px;
		font-weight: bold;
		margin-right: 10px;
	}

	.distribute {
		display: flex;
		align-items: center;
	}

	.hunt {
		width: 27%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		/* padding-top: 20px; */
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
		/* padding: 10px; */
	}

	.el-avatar {
		--el-avatar-bg-color: #F5F5F5;
	}

	.statistics {
		font-size: 14px;
		color: #909399;
		margin-bottom: 15px;
	}

	// 右半部分下边内容
	.el-table--enable-row-transition .el-table__body td.el-table__cell {
		cursor: pointer;
	}

	.el-checkbox__label {
		padding-left: 25px;
	}

	::v-deep .el-table__body {
		width: 96% !important;
		margin: auto;
		// margin-top: 10px;
		// margin-bottom: 20px;
	}

	.el-table__footer {
		width: 96% !important;
		margin: auto;
		// margin-top: 10px;
		// margin-bottom: 20px;
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.box-card {
		width: 480px;
	}

	// 右半部分上边内容
	.impose2 {
		margin-left: 10px;
	}

	.impose1 {
		margin-left: 10px;
	}

	.crew {
		font-size: 14px;
		color: #999;
	}

	.count1 {
		font-size: 25px;
		font-weight: bold;
		color: rgb(100, 213, 114);
	}

	.count2 {
		font-size: 25px;
		font-weight: bold;
		color: rgb(242, 94, 67);
	}

	.count3 {
		font-size: 25px;
		font-weight: bold;
		color: #A9A9A9;
	}

	.count {
		font-size: 25px;
		font-weight: bold;
		color: rgb(45, 140, 240);
	}

	.magnitude {
		width: 71%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	/* .el-card {
		width: 99%;
	} */

	.amount {
		display: flex;
		flex-direction: row;
	}

	.ritherd {
		width: 98%;
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.union1 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(100, 213, 114);
	}

	.union2 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(242, 94, 67);
	}

	.union3 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: #A9A9A9;
	}

	.union {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(45, 140, 240);
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.plane {
		width: 100%;
		// margin-left: 20px;
	}

	// 左半部分上边内容
	@media screen and (max-width: 1200px) {
		.tiem {
			display: none !important;
		}
	}

	.quantum {
		margin-right: 70px;
	}

	.tiem {
		font-size: 14px;
		color: #999;
		line-height: 25px;
		display: flex;
		align-items: center;
	}

	.inventory {
		width: 40%;
		overflow: hidden;
		border-radius: 4px;
	}

	.username {
		margin-left: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.name {
			font-size: 30px;
			color: #222;
		}

		.super {
			font-size: 14px;
			color: #999;
		}
	}

	.stration {
		padding-bottom: 20px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #ccc;
	}

	.message {
		background-color: #ffffff;
		padding: 20px;
	}

	// 上半部分内容
	// .upside {
	//   display: flex;
	// }
	// 整体
	.overall {
		/* padding-left: 10px;
		padding-top: 10px;
		// margin-top: 30px;
		height: 94%; */
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-dialog__body {
		padding: 10px 10px 10px 10px !important;
		height: 215px !important;
		overflow: auto !important;
	}

	::v-deep .el-dialog__body::-webkit-scrollbar {
		display: none;
	}
</style>